import React, { useEffect } from "react";
import "./Ramonster.less";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

import RAMONSTER from '../../../../assets/home/RAMONSTER.png'
import ramonsterMicrochip from '../../../../assets/home/ramonster_microchip.png'

const boxVariant = {
    visible: { opacity: 1, translateY: 0, transition: { duration: 1 } },
    hidden: { opacity: 0, translateY: 100 }
};

const boxVariant1 = {
    visible: { opacity: 1, translateY: 0, transition: { duration: 1, delay: .5 } },
    hidden: { opacity: 0, translateY: 100 }
};

const boxVariant2 = {
    visible: { opacity: 1, translateY: 0, transition: { duration: 1, delay: 1 } },
    hidden: { opacity: 0, translateY: 150 }
};

interface RamonsterProps {
    language: string;
}

const Ramonster: React.FC < RamonsterProps > = ({ language }) => {
    const { t } = useTranslation();

    const control = useAnimation();
    const [ref, inView] = useInView({
         triggerOnce: false, // 只触发一次
         rootMargin: '200px 0px', // 负值会使目标元素提前可见
    });

    useEffect(() => {
        if (inView) {
            control.start("visible");
        } else {
            control.start("hidden");
        }
    }, [control, inView]);

    return (
        <div className="homePage__ramonsterWrap">
      <motion.div
          ref={ref}
          variants={boxVariant}
          initial="hidden"
          animate={control}
        >
         <div className="homePage__ramonsterSlogan"> 
              <img className="homePage__ramonsterSlogan-img"  src={RAMONSTER} alt=""/>
                <div className={language=== 'en' ? "homePage__ramonster-title en" : "homePage__ramonster-title" }>
                    {t("home.ramonster_title")}
                 </div>
          </div>
            </motion.div>
            <div className="homePage__ramonsterRow">
                <div className="homePage__ramonsterContent">
                <motion.div
                  ref={ref}
                  variants={boxVariant1}
                  initial="hidden"
                  animate={control}
                >
                   <div className={language= 'en' ? "homePage__ramonster-info en" : "homePage__ramonster-info" }>
                    <p className="homePage__ramonster-info-p">{t("home.ramonster_info_1")}</p>
                   </div>
                   </motion.div>
                    <motion.div
                  ref={ref}
                  variants={boxVariant2}
                  initial="hidden"
                  animate={control}
                >
                   <div className="homePage__ramonsterMore">
                      <Link to="/about" className="homePage__ramonsterMore-link">{t("home.read_more")}</Link>
                   </div>
                    </motion.div>
                </div>
            </div>
    </div>

    );
};

export default Ramonster;