import React from "react";
import './Footer.less';
import { Link } from "react-router-dom";
import i18n from "i18next";
import { useTranslation } from 'react-i18next';
import { useSelector, useAppDispatch } from '../../redux/hook';
import { globalSlice } from '../../redux/global/slice';
import Logo from '../../assets/common/logo.png';
import iconLng from '../../assets/common/icon_lng.png';

export const Footer: React.FC = () => {
    const { t } = useTranslation();
    const { language } = useSelector(state => state.global);
    const dispatch = useAppDispatch();
    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
        dispatch(
          globalSlice.actions.changeLanguage({
            language: lng
          }),
      );
    }
    return (
        <div className="footerContainer">
            <div className="footerContainer__copyright">
              {t("footer.detail")}<br />
               {t("footer.detail_1")}
            </div>
        </div>
     )    
}