import React from "react";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "./Carousel.less";

import banner1 from "../../../../assets/quality/carousel_1.png";
import banner2 from "../../../../assets/quality/carousel_2.png";
import banner3 from "../../../../assets/quality/carousel_3.png";
import banner4 from "../../../../assets/quality/carousel_4.png";
import banner5 from "../../../../assets/quality/carousel_5.png";
import banner6 from "../../../../assets/quality/carousel_6.png";
import banner7 from "../../../../assets/quality/carousel_7.png";
import banner8 from "../../../../assets/quality/carousel_8.png";
import banner9 from "../../../../assets/quality/carousel_9.png";
import banner10 from "../../../../assets/quality/carousel_10.png";
import banner11 from "../../../../assets/quality/carousel_11.png";
import banner12 from "../../../../assets/quality/carousel_12.png";
import banner13 from "../../../../assets/quality/carousel_13.png";
import banner14 from "../../../../assets/quality/carousel_14.png";
import banner15 from "../../../../assets/quality/carousel_15.png";


const CarouselCompenent: React.FC = () => {
  return (
    <div className="qualityPage__carouselWrap">
      <div className="qualityPage__carousel">
       <Carousel
            showArrows={false} //是否展示左右箭头
            showStatus={false} //是否展示右上角状态
            showIndicators={false} // 是否展示指示器
            infiniteLoop={true} //时候循环
            autoPlay={true} //是否自动播放
            stopOnHover={true} //  鼠标放上去是否停止播放
            showThumbs={false} // 是否展示轮播缩放展示图
            useKeyboardArrows={false} // 是否使用键盘左右按钮滑动
            swipeable={false} // 是否刷新
            dynamicHeight={false} //动态高度
            emulateTouch={true} // 是否拖拽滑动

       >
             <div className="qualityPage__carouselItem">
                <img src={banner1} className="qualityPage__carouselItem-pic" alt="" />
                <img src={banner2} className="qualityPage__carouselItem-pic" alt="" />
              </div>

            <div className="qualityPage__carouselItem">
                 <img src={banner2} className="qualityPage__carouselItem-pic" alt="" />
                 <img src={banner3} className="qualityPage__carouselItem-pic" alt="" />
             
            </div>
             <div className="qualityPage__carouselItem">
                <img src={banner3} className="qualityPage__carouselItem-pic" alt="" />
                <img src={banner4} className="qualityPage__carouselItem-pic" alt="" />
              </div>

            <div className="qualityPage__carouselItem">
                <img src={banner4} className="qualityPage__carouselItem-pic" alt="" />
                <img src={banner5} className="qualityPage__carouselItem-pic" alt="" />
            </div>

            <div className="qualityPage__carouselItem">
               <img src={banner5} className="qualityPage__carouselItem-pic" alt="" />
                <img src={banner6} className="qualityPage__carouselItem-pic" alt="" />
             
            </div>

            <div className="qualityPage__carouselItem">
                <img src={banner6} className="qualityPage__carouselItem-pic" alt="" />
                <img src={banner7} className="qualityPage__carouselItem-pic" alt="" />
            </div>

             <div className="qualityPage__carouselItem">
                <img src={banner7} className="qualityPage__carouselItem-pic" alt="" />
                <img src={banner8} className="qualityPage__carouselItem-pic" alt="" />
            </div>

             <div className="qualityPage__carouselItem">
                <img src={banner8} className="qualityPage__carouselItem-pic" alt="" />
                <img src={banner9} className="qualityPage__carouselItem-pic" alt="" />
            </div>

             <div className="qualityPage__carouselItem">
                <img src={banner9} className="qualityPage__carouselItem-pic" alt="" />
                <img src={banner10} className="qualityPage__carouselItem-pic" alt="" />
            </div>

             <div className="qualityPage__carouselItem">
                <img src={banner10} className="qualityPage__carouselItem-pic" alt="" />
                <img src={banner11} className="qualityPage__carouselItem-pic" alt="" />
            </div>

              <div className="qualityPage__carouselItem">
                <img src={banner11} className="qualityPage__carouselItem-pic" alt="" />
                <img src={banner12} className="qualityPage__carouselItem-pic" alt="" />
            </div>
            <div className="qualityPage__carouselItem">
                <img src={banner12} className="qualityPage__carouselItem-pic" alt="" />
                <img src={banner13} className="qualityPage__carouselItem-pic" alt="" />
            </div>
            <div className="qualityPage__carouselItem">
                <img src={banner13} className="qualityPage__carouselItem-pic" alt="" />
                <img src={banner14} className="qualityPage__carouselItem-pic" alt="" />
            </div>
            <div className="qualityPage__carouselItem">
                <img src={banner14} className="qualityPage__carouselItem-pic" alt="" />
                <img src={banner15} className="qualityPage__carouselItem-pic" alt="" />
            </div>

          </Carousel>
      </div>
    </div>
  );
};

export default CarouselCompenent;