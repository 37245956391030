import React, { useState, useEffect, useRef } from 'react';
import './Culture.less';
import { useTranslation } from "react-i18next";

import { useLocation } from 'react-router-dom';

import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

import cultureVision from '../../../../assets/about/culture_vision.png';
import cultureMission from '../../../../assets/about/culture_mission.png';
import cultureLocation from '../../../../assets/about/culture_location.png';
import cultureHealthy from '../../../../assets/about/culture_healthy.png';


const boxVariant = {
  visible: { opacity: 1, translateY: 0, transition: { duration: 1, delay: 2 } },
  hidden: { opacity: 0, translateY: 100 }
};

interface CultureProps {
  language: string;
}

const Culture : React.FC<CultureProps> = ({language}) => {
    const { t } = useTranslation();

    const location = useLocation();
    const targetRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const hash = window.location.hash;
        if (hash && hash === '#culture') {
          // 将页面滚动到目标组件的位置
          window.scrollTo(0,0);
          targetRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }, [location]);


   const control = useAnimation();
   const [ref, inView] = useInView();

   useEffect(() => {
      if (inView) {
        control.start("visible");
      } else {
        control.start("hidden");
      }
    }, [control, inView]);

    return(
     <motion.div
          ref={ref}
          variants={boxVariant}
          initial="hidden"
          animate={control}
        >
        <div className={language === 'en' ? "aboutCulture en" : "aboutCulture" } ref={targetRef}>
            <div className="aboutCulture__top">
                <div className="aboutCulture__title">
                  {t("about.culture_title")}
                </div>
            </div>
            <div className="aboutCulture__contentRow">
                <div className="aboutCulture__col">
                    <img  src={cultureVision} alt="" className="aboutCulture__colImg" />
                    <div className="aboutCulture__content">
                        <div className="aboutCulture__subtitle">
                        {t("about.culture_subtitle_1")}
                        </div>
                        <div className="aboutCulture__desc">
                        {t("about.culture_desc_1")}
                        </div>
                    </div>
                </div>
                <div className="aboutCulture__col">
                <img  src={cultureMission} alt="" className="aboutCulture__colImg" />
                 <div className="aboutCulture__content">
                    <div className="aboutCulture__subtitle">
                    {t("about.culture_subtitle_2")}
                    </div>
                    <div className="aboutCulture__desc">
                    {t("about.culture_desc_2")}
                    </div>
                    </div>
                </div>
                <div className="aboutCulture__col">
                    <img  src={cultureLocation} alt="" className="aboutCulture__colImg" />
                    <div className="aboutCulture__content">
                        <div className="aboutCulture__subtitle">
                        {t("about.culture_subtitle_3")}
                        </div>
                        <div className="aboutCulture__desc">
                        {t("about.culture_desc_3")}
                        </div>
                    </div>
                </div>
                <div className="aboutCulture__col">
                   <img  src={cultureHealthy} alt="" className="aboutCulture__colImg" />
                   <div className="aboutCulture__content">
                        <div className="aboutCulture__subtitle">
                        {t("about.culture_subtitle_4")}
                        </div>
                        <div className="aboutCulture__desc">
                        {t("about.culture_desc_4")}
                        </div>
                    </div>
                </div>

            </div>
        </div>
        </motion.div>
    )
}
 
export default Culture;