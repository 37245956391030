import React, { useEffect } from "react";
import "./Honors.less";
import { useTranslation } from "react-i18next";

import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

import HonorsItem from '../../../home/components/honorsItem/HonorsItem';

const honorsData = [{
        src: require('../../../../assets/home/honors_1.png'),
        des: '进出口绩优厂商荣誉证书',
        en: 'Outstanding Exporter Certificate'
    },
    {
        src: require('../../../../assets/home/honors_2.png'),
        des: '邓白氏台湾企业精英奖',
        en: 'D&B Taiwan Business Elite Award'
    },
    {
        src: require('../../../../assets/home/honors_3.png'),
        des: 'CORSAIR最佳供应商奖',
        en: 'CORSAIR Best Supplier Award'
    },
    {
        src: require('../../../../assets/home/honors_4.png'),
        des: '福懋科技最佳合作伙伴',
        en: 'FATC Best Partner Award'
    },
    {
        src: require('../../../../assets/home/honors_5.png'),
        des: 'ISO 9001',
        en: 'ISO 9001'
    },
    {
        src: require('../../../../assets/home/honors_6.png'),
        des: 'ISO 14001',
        en: 'ISO 14001'
    },
    {
        src: require('../../../../assets/home/honors_7.png'),
        des: 'ROHS',
        en: 'ROHS'
    },
    {
        src: require('../../../../assets/home/honors_8.png'),
        des: 'CE证书',
        en: 'CE Certificate'
    }
]

const boxVariant = {
    visible: { opacity: 1, translateY: 0, transition: { duration: 1 } },
    hidden: { opacity: 0, translateY: 150 }
};


const boxVariant1 = {
  visible: { opacity: 1, translateY: 0,  transition: { duration: 1, delay: .5 } },
  hidden: { opacity: 0, translateY: 100 }
};

interface HonorsProps {
    language: string;
}


const Honors: React.FC < HonorsProps > = ({ language }) => {
    const { t } = useTranslation();

    const control = useAnimation();
    const [ref, inView] = useInView();

    useEffect(() => {
        if (inView) {
            control.start("visible");
        } else {
            control.start("hidden");
        }
    }, [control, inView]);

    return (
    <div className="qualityPage__honorsWrap">

       <div className={ language === 'en' ? "qualityPage__honorsTop en": "qualityPage__honorsTop"} >
        <motion.div
          ref={ref}
          variants={boxVariant}
          initial="hidden"
          animate={control}
      >
          <div className="qualityPage__honorsTitle">{t("home.honors_title")}</div>
        </motion.div>
          <motion.div
          ref={ref}
          variants={boxVariant1}
          initial="hidden"
          animate={control}
      >
          <div className="qualityPage__honorsDesc">
          {t("home.honors_desc")}
         </div>
         </motion.div>
       </div>
       <div className="qualityPage__honorsItemsWrap">
           {
          honorsData.map((item,index)  => {
            return(
              index < 2 &&
              <HonorsItem
                  delay={0.5}
                  src={item.src}
                  desc={item.des}
                  en={item.en}
                  language={language}
                  key={'homePage__honorsItem_' + index}
               />       
            )
          })
        }
        {
          honorsData.map((item,index)  => {
            return(
              (1 < index && index  < 4 ) &&
              <HonorsItem
                  delay={1}
                  src={item.src}
                  desc={item.des}
                  en={item.en}
                  language={language}
                  key={'homePage__honorsItem_' + index}
               />       
            )
          })
        }


       {
          honorsData.map((item,index)  => {
            return(
              (3 < index && index  < 6 ) &&
              <HonorsItem
                  delay={1.5}
                  src={item.src}
                  desc={item.des}
                  en={item.en}
                  language={language}
                  key={'homePage__honorsItem_' + index}
               />         
            )
          })
        }

         {
          honorsData.map((item,index)  => {
            return(
              (5 < index ) &&
              <HonorsItem
                  delay={2}
                  src={item.src}
                  desc={item.des}
                  en={item.en}
                  language={language}
                  key={'homePage__honorsItem_' + index}
               />         
            )
          })
        }
          
       </div>
    </div>
 
    );
};

export default Honors;