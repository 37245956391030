import React, { useEffect, useRef, useState } from 'react';
import "./Home.less";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useAppDispatch } from '../../redux/hook';
import { globalSlice } from '../../redux/global/slice';
import IntroComponent from "./components/intro/Intro";
import CarouselProductComponent from "./components/carouselProduct/CarouselProduct";
import CarouselDevelopComponent from "./components/carouselDevelop/CarouselDevelop";
import RamonsterComponent from "./components/ramonster/Ramonster";
import HonorsComponent from "./components/honors/Honors";
import CooperComponent from "./components/cooper/Cooper";
import ReadMoreComponent from "./components/readMore/ReadMore";

const HomePage: React.FC = () => {
    const { t } = useTranslation();

    const { language } = useSelector(state => state.global);


    const dispatch = useAppDispatch();
    const videoRef = useRef < HTMLVideoElement | null > (null);
    const [controlsEnabled, setControlsEnabled] = useState(false);

    const playVideo = () => {
        if (videoRef.current) {
            videoRef.current.play().catch(error => {
                // 如果浏览器阻止了自动播放，可以在这里处理错误
                console.error('Auto play failed:', error);
                setControlsEnabled(true)
            });
        }
    };

    useEffect(() => {
        // 在组件挂载后开始播放视频
        playVideo();
        dispatch(
            globalSlice.actions.setGlobalSlice({
                headerIn: false
            })
        );
    }, []);
    useEffect(() => {
        const handleScroll = () => {
            let scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
            if (scrollTop > 100) {
                dispatch(
                    globalSlice.actions.setGlobalSlice({
                        headerIn: true
                    })
                );
            } else {
                dispatch(
                    globalSlice.actions.setGlobalSlice({
                        headerIn: false
                    })
                );
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            dispatch(
                globalSlice.actions.setGlobalSlice({
                    headerIn: true
                })
            );
        };
    }, []);

    return (
        <div className="homePage">
      <div className="homePage__videoWrap">
        <video
          ref={videoRef}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
         poster="./main.jpg"
         src="./main.mp4"
         controls={controlsEnabled}
         autoPlay loop muted playsInline x5-video-player-type="h5" x-webkit-airplay="true" webkit-playsinline="true" x5-playsinline="true"></video>
      </div>
      <div className="content-row">
        <div className="homePage__intro">
          <IntroComponent language={language} />
        </div>
   
         <div className="homePage__ramonster">
           <RamonsterComponent language={language} />
         </div>
        <div className="homePage__product">
           <CarouselProductComponent language={language} />
         </div>
         </div>
         <div className="homePage__devlop">
           <CarouselDevelopComponent language={language} />
         </div>
         <div className="homePage__honors">
           <HonorsComponent language={language} />
         </div>
         <ReadMoreComponent
              url="/quality"
          />
         {  <div className="homePage__cooper content-row">
           <CooperComponent language={language} />
         </div>}
   
    </div>
    );
};

export default HomePage;