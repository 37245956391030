import React, { useEffect } from "react";
import "./Cooper.less";
import { useTranslation } from "react-i18next";

import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

import cooperBig from "../../../../assets/home/cooper_big.png";

const boxVariant = {
  visible: { opacity: 1, translateY: 0,  transition: { duration: 1, delay: 1 } },
  hidden: { opacity: 0, translateY: 100 }
};

const boxVariant1 = {
  visible: { opacity: 1, translateY: 0,  transition: { duration: 1, delay: 1.5 } },
  hidden: { opacity: 0, translateY: 100 }
};

interface CooperProps {
    language: string;
}

const Cooper: React.FC<CooperProps> = ({ language }) => {
  const { t } = useTranslation();

   const control = useAnimation();
   const [ref, inView] = useInView({
         triggerOnce: false, // 只触发一次
         rootMargin: '100px 0px', // 负值会使目标元素提前可见
    });

   useEffect(() => {
      if (inView) {
        control.start("visible");
      } else {
        control.start("hidden");
      }
    }, [control, inView]);

  return (
    <div className="homePage__cooperWrap">
    <motion.div
          ref={ref}
          variants={boxVariant}
          initial="hidden"
          animate={control}
        >
      <div className={language === 'en' ? "cooper__title en" : "cooper__title" }>
      	 {t("home.cooper_title")}
      </div>
       </motion.div>
         <motion.div
          ref={ref}
          variants={boxVariant1}
          initial="hidden"
          animate={control}
        >
      <div className="cooper__picWrap">
          <img src={cooperBig} alt=""  className="cooper__pic" />
      </div>
          </motion.div>
    </div>
   
  );
};

export default Cooper;