import React, { useEffect } from "react";
import "./Intro.less";
import { useTranslation } from "react-i18next";

import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

import goddesses from '../../../../assets/home/goddesses.png';


const boxVariant = {
    visible: { opacity: 1, translateY: 0, transition: { duration: 1 } },
    hidden: { opacity: 0, translateY: 100 }
};

const boxVariant1 = {
    visible: { opacity: 1, translateY: 0, transition: { duration: 1, delay: .5 } },
    hidden: { opacity: 0, translateY: 150 }
};

const boxVariant2 = {
    visible: { opacity: 1, translateY: 0, transition: { duration: 1, delay: 1 } },
    hidden: { opacity: 0, translateY: 100 }
};

interface IntroProps {
    language: string;
}

const Intro: React.FC < IntroProps > = ({ language }) => {
    const { t } = useTranslation();

    const control = useAnimation();
    const [ref, inView] = useInView({
         triggerOnce: false, // 只触发一次
         rootMargin: '200px 0px', // 负值会使目标元素提前可见
    });

    useEffect(() => {
        if (inView) {
            control.start("visible");
        } else {
            control.start("hidden");
        }
    }, [control, inView]);

    return (
        <div className="homePage__introWrap">
          <motion.div
          ref={ref}
          variants={boxVariant}
          initial="visible"
          animate={control}
        >
          <div className="homePage__introSlogan"> 
         
               <img className="homePage__introSlogan-img"  src={goddesses} alt=""/>
                  <div className={language === 'en' ? "homePage__intro-title en" : "homePage__intro-title"}>
                    {t("home.intro_title")}
                   </div>
                
            </div>
             </motion.div>
             <motion.div
          ref={ref}
          variants={boxVariant1}
          initial="hidden"
          animate={control}
        >
                <div className="homePage__introDesc">
                 
                   <div className={language === 'en' ? "homePage__introDesc en" : "homePage__introDesc"}>
                    <p className="homePage__introDesc-p">
                      {t("home.intro_info_1")}
                      <br />
                      {language === 'zh' && <br />}
                      {t("home.intro_info_2")}
                    </p>
                   </div>
                </div>
                 </motion.div>
    </div>
    );
};

export default Intro;