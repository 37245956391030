import React, { useEffect } from 'react';
import './Company.less';
import { useTranslation } from "react-i18next";

import CompanyItem from '../companyItem/CompanyItem';

interface CompanyProps {
    language: string;
}

const Company : React.FC<CompanyProps> = ({ language }) => {
    const { t } = useTranslation();


    return(
        <div className="aboutCompany">
            <div className="aboutCompany__row">
                <CompanyItem 
                    delay={0}
                    name={t("about.company_1")}
                    address_1= {t("about.company_address_1")}
                    address_2={t("about.company_address_1_1")}
                />
                 
                 <CompanyItem 
                    delay={0.5}
                    name={t("about.company_2")}
                    address_1= {t("about.company_address_2")}
                    address_2={t("about.company_address_2_1")}
                />
                <CompanyItem 
                    delay={1}
                    name={t("about.company_3")}
                    address_1= {t("about.company_address_3")}
                    address_2={t("about.company_address_3_1")}
                />
                <CompanyItem 
                    delay={1.5}
                    name={t("about.company_4")}
                    address_1= {t("about.company_address_4")}
                    address_2={t("about.company_address_4_1")}
                />
            </div>
        </div>
    )
}

export default Company;