import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';

import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

interface CompanyItemProps {
    delay: number;
    name: string;
    address_1: string;
    address_2: string;
}

const CompanyItem: React.FC < CompanyItemProps >= ({ delay, name, address_1, address_2 }) => {
    const { t } = useTranslation();


    const boxVariant = {
        visible: { opacity: 1, translateY: 0, transition: { duration: 1, delay: delay } },
        hidden: { opacity: 0, translateY: 100 }
    };

    const control = useAnimation();
    const [ref, inView] = useInView();

    useEffect(() => {
        if (inView) {
            control.start("visible");
        } else {
            control.start("hidden");
        }
    }, [control, inView]);

    return (
        <motion.div
              ref={ref}
              variants={boxVariant}
              initial="hidden"
              animate={control}
            >
          <div className="aboutCompany__item">
                     <div className="aboutCompany__itemName">{name}</div>
                     <div className="aboutCompany__address">
                        <i className="aboutCompany__address-icon"></i>
                        <span className="aboutCompany__address-detail">
                          {address_1}
                        </span>
                     </div>
                     <div className="aboutCompany__subAddress">
                         {address_2}
                     </div>
                 </div>
      </motion.div>

    )
}

export default CompanyItem;