import React, { useEffect } from "react";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "./CarouselDevelop.less";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

import banner1 from "../../../../assets/home/carousel_develop_1.png";
import banner2 from "../../../../assets/home/carousel_develop_2.png";
import banner3 from "../../../../assets/home/carousel_develop_3.png";
import banner4 from "../../../../assets/home/carousel_develop_4.png";

const boxVariant = {
    visible: { opacity: 1, translateY: 0, transition: { duration: 1 } },
    hidden: { opacity: 0, translateY: 100  }
};

const boxVariant1 = {
    visible: { opacity: 1, translateY: 0, transition: { duration: 1, delay: .5 } },
    hidden: { opacity: 0, translateY: 100 }
};

const boxVariant2 = {
    visible: { opacity: 1, translateY: 0, transition: { duration: 1, delay: 1 } },
    hidden: { opacity: 0, translateY: 150 }
};

interface CarouselDevelopProps {
    language: string;
}


const CarouselDevelop: React.FC<CarouselDevelopProps> = ({ language }) => {
    const { t } = useTranslation();

    const control = useAnimation();
    const [ref, inView] = useInView({
         triggerOnce: false, // 只触发一次
         rootMargin: '200px 0px', // 负值会使目标元素提前可见
    });

    useEffect(() => {
        if (inView) {
            control.start("visible");
        } else {
            control.start("hidden");
        }
    }, [control, inView]);

    return (
     <div className="homePage__developCarouselWrap">
       <div className="developCarousel__content">
        <motion.div
                  ref={ref}
                  variants={boxVariant}
                  initial="hidden"
                  animate={control}
                >
             <div className="homePage__developTitle">
              <p className={language === 'en' ? "homePage__developTitle-p en" : "homePage__developTitle-p" }>{t("home.carousel_develop")}</p>
           </div>
          </motion.div> 
            <motion.div
                  ref={ref}
                  variants={boxVariant1}
                  initial="hidden"
                  animate={control}
                >
          <div className="homePage__developCarousel">
           <Carousel
                showArrows={false} //是否展示左右箭头
                showStatus={false} //是否展示右上角状态
                showIndicators={true} // 是否展示指示器
                infiniteLoop={true} //时候循环
                autoPlay={true} //是否自动播放
                stopOnHover={true} //  鼠标放上去是否停止播放
                showThumbs={false} // 是否展示轮播缩放展示图
                useKeyboardArrows={false} // 是否使用键盘左右按钮滑动
                swipeable={false} // 是否刷新
                dynamicHeight={false} //动态高度
                emulateTouch={false} // 是否拖拽滑动
            >

                <div className="homePage__developCarouselItem">
                  <img src={banner1} className="homePage__developCarouselItem-pic" alt="" />
                </div>
                <div className="homePage__developCarouselItem">
                  <img src={banner2} className="homePage__developCarouselItem-pic" alt="" />
                </div>
                <div className="homePage__developCarouselItem">
                  <img src={banner3} className="homePage__developCarouselItem-pic" alt="" />
                </div>
                <div className="homePage__developCarouselItem">
                  <img src={banner4} className="homePage__developCarouselItem-pic" alt="" />
                </div>
              </Carousel>
          </div>
          <div className="developCarousel__title">
            {t("home.carousel_develop_title")}
          </div>
          
          <div className="developCarousel__desc">
               {t("home.carousel_develop_desc")}
          </div>
           </motion.div>
     </div>
       <motion.div
                  ref={ref}
                  variants={boxVariant2}
                  initial="hidden"
                  animate={control}
                >
                   <div className="homePage__readMore">
                      <Link to="/product" className="homePage__readMore-link">{t("home.read_more")}</Link>
                   </div>
             </motion.div>

    </div>

    );
};

export default CarouselDevelop;