

import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";


interface ReadMoreProps {
    url: string;
}


const ReadMore: React.FC<ReadMoreProps> = ({url}) => {
   const { t } = useTranslation();


  const boxVariant = {
    visible: { opacity: 1, translateY: 0, transition: { duration: 1, delay: 1 } },
    hidden: { opacity: 0, translateY: 100 }
  };

   const control = useAnimation();
   const [ref, inView] = useInView();

   useEffect(() => {
      if (inView) {
        control.start("visible");
      } else {
        control.start("hidden");
      }
    }, [control, inView]);

    return(
      <motion.div
              ref={ref}
              variants={boxVariant}
              initial="hidden"
              animate={control}
            >
         <div className="homePage__readMore">
              <Link to={url} className="homePage__readMore-link">{t("home.read_more")}</Link>
          </div>
      </motion.div>
      
    )
}

export default ReadMore;